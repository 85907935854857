<template>
  <div class="bg-white text-black antialiased dark:bg-zinc-900 dark:text-gray-200">
    <div v-if="!isSidebarOpened" class="fixed lg:hidden bottom-0 z-10 w-full block text-xs sm:text-base">
      <div class="bg-gray-100 text-gray-600 dark:bg-zinc-950 pb-1 pt-1 dark:text-gray-300 w-full overflow-hidden flex items-center justify-evenly px-2">
        <nav class="flex justify-between border-r dark:border-zinc-900 pr-2">
          <NuxtLink class="flex flex-wrap justify-center items-center p-1" to="/meus_eventos">
            <icon-calendar class="block text-gray-400 dark:text-zinc-700" />
            <span class="block w-full mt-1 text-center">eventos</span>
          </NuxtLink>

          <NuxtLink class="flex flex-wrap justify-center items-center p-1" to="/carteira">
            <icon-wallet class="block text-gray-400 dark:text-zinc-700" />
            <span class="block w-full mt-1 text-center">carteira</span>
          </NuxtLink>

          <NuxtLink class="flex flex-wrap justify-center items-center p-1" to="/conta">
            <icon-user class="block text-gray-400 dark:text-zinc-700" />
            <span class="block w-full mt-1 text-center">conta</span>
          </NuxtLink>
        </nav>
        <div class="flex flex-1 justify-end">
          <button @click.stop.prevent="onClickSidebarToggle" data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button"
            class="flex flex-wrap justify-center items-center p-1">
            <span class="sr-only">Open sidebar</span>
            <svg class="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
            </svg>
            <span class="block w-full mt-1 text-center">menu</span>
          </button>
        </div>
      </div>
    </div>
    <!-- <button @click.stop.prevent="onClickSidebarToggle" data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button"
      class="fixed z-20 top-0 right-4 inline-flex items-center p-2 mt-2 ml-3 text-sm text-white bg-gray-900 border-gray-300 border-2 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
      <span class="sr-only">Open sidebar</span>
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
      </svg>
    </button> -->

    <!-- fixed top-0 left-0  -->
    <aside id="default-sidebar" v-on-click-outside="onClickOutsideSidebar" :class="['fixed z-20 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0', { 'translate-x-0': isSidebarOpened }]" aria-label="Sidebar">
      <div class="h-full px-3 py-4 w-64 bg-gray-50 dark:bg-zinc-950">

        <div class="flex flex-wrap w-full h-full relative content-start">

          <!-- logo -->
          <div class="p-1 w-full mb-4 opacity-40 pb-0">
            <div class="w-16 sm:w-24">
              <HeaderLogo />
            </div>
          </div>

          <!-- block font-medium w-full divide-y dark:divide-gray-800 border-y dark:border-gray-800 -->
          <!-- <nav class="" v-if="event.slug"> -->
          <!-- <h3 class="mb-4 px-1" v-if="">{{ event.name }}</h3> -->
          <!-- scrollable menu -->
          <div class="overflow-y-auto w-full h-[calc(100%-100px)] sm:h-auto" v-if="isInsideEvent && route.params.slug">
            <p class="mt-0 mb-2 px-1 font-bold text-xs opacity-40 uppercase block">
              painel do evento
            </p>
            <nav>
              <NuxtLink class="manager-nav-item" :to="`/meus_eventos/${route.params.slug}`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-home" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M5 12l-2 0l9 -9l9 9l-2 0"></path>
                  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                </svg>
                <span>Geral</span>
              </NuxtLink>
              <NuxtLink class="manager-nav-item" v-if="userRole === 'role_admin'" :to="`/meus_eventos/${route.params.slug}/orders`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-cash" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M7 9m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"></path>
                  <path d="M14 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2"></path>
                </svg>
                <span>Pedidos</span>
              </NuxtLink>
              <NuxtLink class="manager-nav-item" :to="`/meus_eventos/${route.params.slug}/checkin`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-qrcode" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
                  <path d="M7 17l0 .01"></path>
                  <path d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
                  <path d="M7 7l0 .01"></path>
                  <path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
                  <path d="M17 7l0 .01"></path>
                  <path d="M14 14l3 0"></path>
                  <path d="M20 14l0 .01"></path>
                  <path d="M14 14l0 3"></path>
                  <path d="M14 20l3 0"></path>
                  <path d="M17 17l3 0"></path>
                  <path d="M20 17l0 3"></path>
                </svg>
                <span>Lista de Entrada</span>
              </NuxtLink>
            </nav>

            <p v-if="userRole === 'role_admin'" class="mt-4 mb-2 px-1 font-bold text-xs opacity-40 uppercase block">
              lotes/produtos
            </p>

            <nav>
              <NuxtLink class="manager-nav-item" v-if="userRole === 'role_admin'" :to="`/meus_eventos/${route.params.slug}/products`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-ticket" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M15 5l0 2"></path>
                  <path d="M15 11l0 2"></path>
                  <path d="M15 17l0 2"></path>
                  <path d="M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2"></path>
                </svg>
                <span>Ingressos</span>
              </NuxtLink>
              <NuxtLink class="manager-nav-item" v-if="userRole === 'role_admin'" :to="`/meus_eventos/${route.params.slug}/coupons`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-discount-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 15l6 -6"></path>
                  <circle cx="9.5" cy="9.5" r=".5" fill="currentColor"></circle>
                  <circle cx="14.5" cy="14.5" r=".5" fill="currentColor"></circle>
                  <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
                </svg>
                <span>Cupons de desconto</span>
              </NuxtLink>
              <NuxtLink class="manager-nav-item" v-if="userRole === 'role_admin'" :to="`/meus_eventos/${route.params.slug}/tickets`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-vip" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 5h18"></path>
                  <path d="M3 19h18"></path>
                  <path d="M4 9l2 6h1l2 -6"></path>
                  <path d="M12 9v6"></path>
                  <path d="M16 15v-6h2a2 2 0 1 1 0 4h-2"></path>
                </svg>
                <span>Listas VIP</span>
              </NuxtLink>
            </nav>

            <p v-if="userRole === 'role_admin'" class="mt-4 mb-2 px-1 font-bold text-xs opacity-40 uppercase block">
              divulgação
            </p>

            <nav>
              <NuxtLink class="manager-nav-item" v-if="userRole === 'role_admin'" :to="`/meus_eventos/${route.params.slug}/galeria`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-photo-heart" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M15 8h.01"></path>
                <path d="M11.5 21h-5.5a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5"></path>
                <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l1.5 1.5"></path>
                <path d="M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z"></path>
              </svg>
              <span>Galeria</span>
              </NuxtLink>
              <!-- Divulgação do evento -->
              <NuxtLink class="manager-nav-item" v-if="userRole === 'role_admin'" :to="`/meus_eventos/${route.params.slug}/partners`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users-group" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1"></path>
                  <path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M17 10h2a2 2 0 0 1 2 2v1"></path>
                  <path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M3 13v-1a2 2 0 0 1 2 -2h2"></path>
                </svg>
                <span>Parceiros</span>
              </NuxtLink>
              <!-- <NuxtLink class="manager-nav-item" v-if="userRole === 'role_admin'" :to="`/meus_eventos/${route.params.slug}/wallet`">
                Financeiro

              </NuxtLink> -->
            </nav>

            <p v-if="userRole === 'role_admin'" class="mt-4 mb-2 px-1 font-bold text-xs opacity-40 uppercase block">
              configuração
            </p>
            <nav>
              <NuxtLink class="manager-nav-item" v-if="userRole === 'role_admin'" :to="`/meus_eventos/${route.params.slug}/editar`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                  <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                  <path d="M16 5l3 3"></path>
                </svg>
                <span>Editar evento</span>
              </NuxtLink>
              <NuxtLink class="manager-nav-item" v-if="userRole === 'role_admin'" :to="`/meus_eventos/${route.params.slug}/users`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                </svg>
                <span>Usuários</span>
              </NuxtLink>
            </nav>
          </div>

          <div class="overflow-y-auto" v-if="(!isInsideEvent && !route.params.slug && currentProducer && currentProducer.slug) || (isInsideProducer && currentProducer && currentProducer.slug) || (isInsideMyEvents && currentProducer?.slug)">
            <nav>
              <NuxtLink class="manager-nav-item" :to="`/meus_eventos/`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-home" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M5 12l-2 0l9 -9l9 9l-2 0"></path>
                  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                </svg>
                <span>Meus Eventos</span>
              </NuxtLink>
            </nav>
            <p class="mt-4 mb-2 px-1 font-bold text-xs opacity-40 uppercase block">
              produtor
            </p>
            <nav>

              <NuxtLink class="manager-nav-item" :to="`/crie-seu-evento`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5"></path>
                  <path d="M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z"></path>
                </svg>
                <span>Criar evento</span>
              </NuxtLink>
              <NuxtLink class="manager-nav-item" :to="`/p/${currentProducer.slug}/edit`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5"></path>
                  <path d="M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z"></path>
                </svg>
                <span>Editar perfil</span>
              </NuxtLink>
              <NuxtLink class="manager-nav-item" :to="`/p/${currentProducer.slug}/campaigns`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"></path>
                  <path d="M3 7l9 6l9 -6"></path>
                </svg>
                <span>Mailing</span>
              </NuxtLink>
              <NuxtLink class="manager-nav-item" :to="`/p/${currentProducer.slug}/guestlists`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-list" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 6l11 0"></path>
                  <path d="M9 12l11 0"></path>
                  <path d="M9 18l11 0"></path>
                  <path d="M5 6l0 .01"></path>
                  <path d="M5 12l0 .01"></path>
                  <path d="M5 18l0 .01"></path>
                </svg>
                <span>Listas</span>
              </NuxtLink>
              <NuxtLink v-if="false" class="manager-nav-item" :to="`/p/${currentProducer.slug}/campaigns`">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                </svg>
                <span>Gerenciar Usuários</span>
              </NuxtLink>
            </nav>
          </div>

          <div class="w-full fixed bottom-4 sm:absolute sm:bottom-0 flex justify-start mb-0 opacity-40 hover:opacity-100 transition">
            <ProfileSwitch />
          </div>
        </div>
      </div>
    </aside>

    <div :class="['sm:p-4 sm:ml-64 min-h-screen pb-32', { 'blur-2xl pointer-events-none sm:pointer-events-auto sm:blur-0': isSidebarOpened }]">
      <!-- <AdminEventHeader :event="event" v-if="isInsideEvent && route.params.slug && event && route.name != 'meus_eventos-slug'" /> -->
      <slot />
    </div>
  </div>
</template>

<script setup>

import { useUserStore } from '@/store/user.js'
import { useMainStore } from '@/store/root.js'
import { vOnClickOutside } from '@vueuse/components'

const userStore = useUserStore()
const mainStore = useMainStore()
const router = useRouter()
const route = useRoute()

import { storeToRefs } from 'pinia'
const { currentUser, loggedIn: isLoggedIn, isProducer, currentProducer, token } = storeToRefs(userStore)

const runtimeConfig = useRuntimeConfig()
const { baseApiUrl: baseUrl } = runtimeConfig.public

const { isSidebarOpened } = storeToRefs(mainStore)

const isInsideMyEvents = computed(() => {
  return route.path === '/meus_eventos' || route.path === '/meus_eventos/'
  //  || route.path.toString().startsWith('/carteira')
})

const isInsideProducer = computed(() => {
  return route.path.toString().startsWith('/p/')
})

const isInsideEvent = computed(() => {
  return route.path.startsWith('/meus_eventos/') && route.params.slug != ''
})

const onClickSidebarToggle = async () => {
  // console.log('clicked', isSidebarOpened.value)
  await mainStore.sidebarOpened(!isSidebarOpened.value)
}

const onClickOutsideSidebar = async () => {
  if(!isSidebarOpened.value) return
  await mainStore.sidebarOpened(false)
}

watch(route, () => {
  onClickOutsideSidebar()
});

const userRole = ref('role_admin')

const { data: { value: event }, refresh } = await useFetch(() => `${baseUrl}/api/manager/events/${route.params.slug}.json`, {
  headers: { Authorization: token },
  transform (data) {
    console.log(data.data.user_role)
    userRole.value = data.data.user_role
    return data.data

  }
  // immediate: true
})

// if(route.params.slug && !event) {
//   refresh()
// }

// TODO FIXME

// const userRole = computed(() => {
//   try {
//     return event?.user_role
//   } catch(_) {
//     return null
//   }
// })

useHead({
  htmlAttrs: {
    lang: 'pt-BR',
    class: 'bg-zinc-900 light:bg-white'
  }
})

</script>
